


































import {
  defineComponent,
  ref,
  computed,
  watchEffect,
} from "@vue/composition-api";
import moment from "moment";
import { User } from "@/store";

interface Props {
  users: Array<User>;
  loading: boolean;
  total: number;
  showDeleted: boolean;
}

export interface Pagination {
  page: number;
  itemsPerPage: number;
  pageStart: number;
  pageStop: number;
  pageCount: number;
  itemsLenth: number;
}

interface Header {
  text: string;
  value: string;
  align?: string;
  sortable?: boolean;
}

export default defineComponent({
  name: "UsersTable",
  props: {
    users: Array,
    loading: Boolean,
    total: Number,
    showDeleted: Boolean,
  },
  setup(props: Props, { emit }) {
    const sortBy = ref<string>("");
    const sortDesc = ref<boolean>();
    const headers = computed<Header[]>(() => {
      const heads = [
        { text: "Username", value: "username", sortable: true },
        { text: "First Name", value: "firstName", sortable: true },
        { text: "Last Name", value: "lastName", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Role", value: "role.name", sortable: true },
        { text: "MFA Settings", value: "mfaEnabled", sortable: true },
      ];
      if (props.showDeleted) {
        heads.push({ text: "Deleted", value: "deleted", sortable: true });
      }
      return heads;
    });

    const displayUsers = computed<User[]>(() => {
      return !props.showDeleted
        ? props.users.filter((user) => user.deleted === null)
        : props.users;
    });

    const page = ref<number>(1);
    const itemsPerPage = ref<number>(10);
    const pageCount = computed<number>(() =>
      Math.ceil(props.total / itemsPerPage.value)
    );
    const search = ref<string>("");

    function rowClick($event: User) {
      emit("rowClick", $event);
    }

    function getDate(date: string) {
      return moment(date).format("MM/DD/YYYY");
    }

    watchEffect(() => {
      if (props.showDeleted) {
        sortBy.value = "deleted";
        sortDesc.value = true;
      } else {
        sortBy.value = "";
        sortDesc.value = false;
      }
    });

    function updateSortBy(sort: string) {
      if (sortBy.value !== sort) {
        sortBy.value = sort;
      } else {
        sortDesc.value = !sortDesc.value;
      }
    }

    return {
      headers,
      page,
      pageCount,
      itemsPerPage,
      rowClick,
      search,
      displayUsers,
      getDate,
      sortDesc,
      sortBy,
      updateSortBy,
    };
  },
});
