























import {
  defineComponent,
  computed,
  onBeforeMount,
  ref,
} from "@vue/composition-api";
import { useStore, useUserService, useRouter } from "@/composables/providers";
import { User } from "@/store";
import { UserGetters, UserActions } from "@/store/user";
import UserTable from "@/components/tables/Users.vue";

export default defineComponent({
  name: "UsersList",
  components: {
    UserTable,
  },
  setup() {
    const store = useStore();
    const service = useUserService();
    const router = useRouter();

    const users = computed<User[]>(() => store.getters[UserGetters.USERS]);
    const loading = computed<boolean>(() => store.getters[UserGetters.LOADING]);
    const total = computed<number>(() => store.getters[UserGetters.TOTAL]);
    const showDeleted = ref<boolean>(false);

    onBeforeMount(async () => {
      await store.dispatch(UserActions.LIST, { service });
    });

    async function navigateToUser($event: User) {
      await store.dispatch(UserActions.SELECT, {
        id: $event.id,
        service,
      });
      router.push({
        name: "UserUpdate",
        params: { id: $event.id },
      });
    }

    return {
      users,
      loading,
      total,
      navigateToUser,
      showDeleted,
    };
  },
});
